@font-face {
  font-family: 'Satoshi-Variable';
  src: url('Satoshi-Variable.woff') format('woff');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}
* {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Satoshi-Variable' !important; 

}
